// plugins/logger.client.js

import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const logLevels = {
    DEBUG: 'debug',
    INFO: 'info',
    WARN: 'warn',
    ERROR: 'error',
  };

  const formatMessage = (args) => {
    return args
      .map((arg) => {
        if (typeof arg === 'object') {
          return JSON.stringify(arg, null, 2);
        }
        return arg;
      })
      .join(' ');
  };

  const log = (level, ...args) => {
    const message = formatMessage(args);
    const logMessage = {
      level,
      message,
      timestamp: new Date().toISOString(),
    };

    if (process.env.NODE_ENV !== 'production') {
      console[level](`${logMessage.timestamp} [${logMessage.level.toUpperCase()}]: ${logMessage.message}`);
    }
  };

  const logger = {
    debug: (...args) => log(logLevels.DEBUG, ...args),
    info: (...args) => log(logLevels.INFO, ...args),
    warn: (...args) => log(logLevels.WARN, ...args),
    error: (...args) => log(logLevels.ERROR, ...args),
  };

  // Inject the logger into the Nuxt app
  nuxtApp.provide('log', logger);
});
