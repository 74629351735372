// utility.js

import Utility from '~/server/utils/utils';

export const defaultNumberLocales = {
  en: 'en-US',
  fr: 'fr-FR',
  es: 'es-ES',
  de: 'de-DE',
  it: 'it-IT',
  pt: 'pt-PT',
  ru: 'ru-RU',
};

export const getProductURL = (product) => {
  if (
    product.provider == 'tiqets' ||
    product.provider == 'viator' ||
    product.provider == 'musement' ||
    product.provider == 'getyourguide' ||
    product.provider == 'headout'
  ) {
    return `/p/${Utility.sanitizeProductName(product.name)}-${product.code}`;
  } else {
    return product.url;
  }
};

export const openProduct = (product, customOpenURL = null) => {
  if (customOpenURL) {
    navigateTo(customOpenURL);
    return;
  }
  const mixpanel = useMixpanel();
  const localePath = useLocalePath();
  const productURL = getProductURL(product);
  try {
    mixpanel.track('Open Tour', {
      'Product ID': product.original_id,
      'Product Name': product.name,
      'Provider Name': product.provider,
      'Provider ID': product.providerID,
    });
  } catch (error) {
    console.error('Error tracking event:', error);
  }

  // Add product ID to recently viewed products cookie
  saveProductInCookie(product.id);

  if (
    product.provider == 'tiqets' ||
    product.provider == 'viator' ||
    product.provider == 'musement' ||
    product.provider == 'getyourguide' ||
    product.provider == 'headout'
  ) {
    window.open(localePath(productURL), '_blank');
  } else {
    window.open(localePath(productURL), '_blank');
  }
};

/**
 * Formats time from seconds to a more readable string format.
 *
 * Formatting rules:
 * - For less than 1 hour: displays minutes only (e.g., "15 minutes", "30 minutes").
 * - For 1 hour or more: displays hours in decimal format if necessary (e.g., "1 hour", "2.5 hours").
 * - For 24 hours or more: displays days (e.g., "2 days").
 *
 * Examples:
 * - 900 seconds (15 minutes) => "15 minutes"
 * - 1800 seconds (30 minutes) => "30 minutes"
 * - 3600 seconds (1 hour) => "1 hour"
 * - 5400 seconds (1.5 hours) => "1.5 hours"
 * - 7200 seconds (2 hours) => "2 hours"
 * - 9000 seconds (2.5 hours) => "2.5 hours"
 * - 86400 seconds (1 day) => "1 day"
 * - 172800 seconds (2 days) => "2 days"
 *
 * @param {number} seconds - The time in seconds to format.
 * @returns {string} The formatted time string.
 */
export function formatHours(seconds, t) {
  if (!t) {
    throw new Error('t is required');
  }

  //TODO: check for 0 minutes duration
  const minutes = Math.round(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const minOverHour = minutes % 60;

  if (hours < 1) {
    if (minutes === 1) return minutes + ' ' + t('minute'); // Singular minute
    return minutes + ' ' + t('minutes'); // minutes
  }
  if (hours >= 24) {
    const days = Math.floor(hours / 24);
    if (days >= 2) return days + ' ' + t('days');
    return days + ' ' + t('day'); // days singular
  }
  if (minOverHour === 0) {
    if (hours === 1) return hours + ' ' + t('hour'); // Singular hour
    return hours + ' ' + t('hours'); // hours
  }
  const decimalHours = (hours + minOverHour / 60).toFixed(1);
  return decimalHours + ' ' + t('hours'); // hours with decimal
}

export function saveDestinationInCookie(destinationKey) {
  if (!destinationKey) return;
  //Lets add the destination to the recently viewed destination cookie
  // Add destination to recently viewed cookie
  const RVDestinationsCookie = useCookie('RVDestinations');
  const recentlyViewedArray = RVDestinationsCookie.value ? RVDestinationsCookie.value : [];
  const obj = {
    id: destinationKey,
    date: new Date(),
  };
  //Lets check if the recently viewed destination is already in the array
  recentlyViewedArray.some((destination) => destination.id === destinationKey)
    ? null
    : recentlyViewedArray.unshift(obj);
  //Limit the array to 10 elements
  recentlyViewedArray.length > 10 ? recentlyViewedArray.pop() : null;
  //Save the array in the cookie
  RVDestinationsCookie.value = recentlyViewedArray;
}

export function saveProductInCookie(productID) {
  if (!productID) return;
  //Lets add the destination to the recently viewed destination cookie
  // Add destination to recently viewed cookie
  const RVProductsCookie = useCookie('RVProducts');
  const recentlyViewedArray = RVProductsCookie.value ? RVProductsCookie.value : [];
  const obj = {
    id: productID,
    date: new Date(),
  };
  //Lets check if the recently viewed destination is already in the array
  recentlyViewedArray.some((product) => product.id === productID) ? null : recentlyViewedArray.unshift(obj);
  //Limit the array to 10 elements
  recentlyViewedArray.length > 10 ? recentlyViewedArray.pop() : null;
  //Save the array in the cookie
  RVProductsCookie.value = recentlyViewedArray;
}

export function getRelativeTimeString(date) {
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count >= 1) {
      return count === 1 ? `1 ${interval.label} ago` : `${count} ${interval.label}s ago`;
    }
  }

  return 'just now';
}

export function formatDate(dateString) {
  const { t, d } = useI18n();
  const isoDate = new Date(dateString.toISOString() + 'T00:00:00Z').toISOString();
  return d(isoDate, 'short');
}
