<template>
  <header>
    <nav
      class="border-gray-200 bg-white dark:bg-[#121212] md:left-1/2 mx-auto md:fixed md:transform md:-translate-x-1/2 z-50"
      :class="
        isMapPage
          ? 'w-full md:w-[98%] md:mt-5 rounded-full md:py-2 px-2 md:px-5'
          : 'md:w-full md:py-4 px-2 max-w-screen-xl'
      "
    >
      <div class="flex justify-between items-center mx-auto w-full space-x-4">
        <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="/img/logo.svg" class="h-16 sm:h-16 md:h-14" alt="Logo" />
        </a>
        <div class="flex w-full md:justify-between justify-end items-center space-x-2">
          <div
            class="md:flex-grow flex items-center space-x-4 md:static left-0 right-0 z-50 md:px-1"
            :class="!showTopSearchButton ? 'fixed bottom-2' : ''"
          >
            <SearchBar
              v-if="showSearchBar"
              :popularSearches="popularSearches"
              :cityID="cityID"
              :currentPageName="currentPageName"
              class="md:flex-grow w-full md:relative"
            />
          </div>
          <button
            @click="openModal"
            class="border-1 text-gray-800 dark:text-white border-2 border-solid border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-[10px] lg:px-5 py-[10px] lg:py-2.5 mr-4 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
          >
            <div class="flex items-center justify-center w-full relative">
              <!-- Language icon -->
              <img src="/img/icons/language_flag.svg" class="dark:filter dark:invert dark:invert-1" />
              <!-- Flag image -->
              <img
                :src="`/img/flag/${flag}.svg`"
                class="h-3 md:h-3 rounded bg-cover absolute bottom-0 right-0 left-[4px] transform translate-x-1/3 translate-y-1/3"
                :alt="`${locale} Flag`"
              />
            </div>
          </button>
          <button
            @click="openContactUsChat"
            class="border-1 md:hidden text-gray-800 dark:text-white border-2 border-solid bg-white dark:bg-transparent dark:focus:bg-gray-700 dark:focus:ring-gray-800 border-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-2 lg:px-3 py-2 lg:py-2.5 mr-2"
          >
            <div class="flex items-center justify-center w-full relative">
              <!-- Language icon -->
              <img src="/img/icons/contact_support.svg" class="dark:filter dark:invert dark:invert-1" />
            </div>
          </button>
        </div>
      </div>
    </nav>
    <AdaptableModal :isOpen="isModalOpen" @close="closeModal">
      <template #header>
        <div class="flex items-center space-x-2 w-full mb-0">
          <img src="/img/icons/language.svg" class="dark:filter dark:invert dark:invert-1" />
          <h2 class="text-lg font-semibold">{{ $t('Regional Setting') }}</h2>
        </div>
      </template>
      <RegionalSettings @settingsUpdated="closeModal"></RegionalSettings>
    </AdaptableModal>
  </header>
</template>

<style scoped>
/* Your styles here */
</style>

<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RegionalSettings from './RegionalSettings.vue';
import AdaptableModal from './Utils/AdaptableModal.vue';

const nuxtApp = useNuxtApp();
const route = useRoute();
const router = useRouter();
const path = computed(() => route.path);
const pathName = computed(() => route.name);
const isModalOpen = ref(false);
const popularSearches = ref([]);
const currentPageName = ref(null);
const { locale } = useI18n();

if (process.client) {
  var userLang = navigator.language || navigator.userLanguage;
}

const isMapPage = computed(() => {
  if (route?.name?.includes('map')) {
    return true;
  } else {
    return false;
  }
});

function openContactUsChat() {
  const button = document.getElementsByClassName('arcu-message-button')[0];
  button.click();
}

//This is needed to add a bottom margin to the search bar when on the product page because the product page has the booking button on the bottom of the page
const showTopSearchButton = computed(() => {
  return (route?.name?.includes('product') || route?.name?.includes('map')) ?? false;
});

const flag = computed(() => {
  switch (locale.value) {
    case 'en':
      if (userLang === 'en-GB') {
        return 'gb';
      } else {
        return 'us';
      }
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'es':
      return 'es';
    case 'it':
      return 'it';
    case 'pt':
      return 'pt';
    case 'ru':
      return 'ru';
    case 'zh':
      return 'cn';
    case 'nl':
      return 'nl';
    default:
      return 'us';
  }
});

try {
  watch(locale, () => {
    flag.value = flag.value;
  });
} catch (error) {
  console.error('Error in watch(locale, () => { ... }):', error);
}

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
});

let cityID = props.data?.cityID;

nuxtApp.hook('page:finish', () => {
  updateSearchBarVisibility();
});

// Check if the path is the homepage or homepage with language prefix
const showSearchBar = ref((!pathName?.value?.includes('search') && !pathName?.value?.includes('index')) ?? false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const updateSearchBarVisibility = () => {
  showSearchBar.value = !pathName?.value?.includes('search') && !pathName?.value?.includes('index');
};

// Initial check on component mount
onMounted(() => {
  router.isReady().then(() => {
    updateSearchBarVisibility(path.value);
  });
});

watch(props, () => {
  if (props.data && props.data.popularSearches) {
    popularSearches.value = props.data.popularSearches;
  }
  if (props.data) {
    cityID = props.data?.cityID;
  }
  if (props.data && props.data.currentPageName) {
    currentPageName.value = props.data.currentPageName;
  }
});
</script>
