// plugins/numberFormat.js
import { defineNuxtPlugin } from '#app';
import { datetimeFormats } from '~/i18n/datetime-format'; // Import your custom number formats

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.mixin({
    methods: {
      localizedDate(date, formatType) {
        const { locale } = useI18n();
        const currentLocale = locale.value;
        let format = datetimeFormats[currentLocale];
        if (!format) {
          format = datetimeFormats['en-US'];
        }
        let options = { dateStyle: formatType };

        // Check if date is a valid Date object
        if (!(date instanceof Date) || isNaN(date)) {
          // console.error('Invalid date provided:', date);
          return 'Invalid Date';
        }

        let formattedDate = new Intl.DateTimeFormat(currentLocale, options).format(date);
        return formattedDate;
      },
    },
  });
});
