<template>
  <div v-if="showChat" class="flex flex-col">
    <div
      class="bg-gray-100 dark:bg-zinc-900 border-gray-300 dark:border-gray-600 flex justify-between items-center p-4"
    >
      <button @click.stop="closeChat" class="text-gray-500 hover:text-gray-700 text-xl">
        <img src="/img/icons/chevron_left.svg" class="icon dark:filter dark:invert dark:invert-1 w-5 h-5" />
      </button>
      <button @click="cleanUpChat" class="text-gray-500 hover:text-gray-700 text-xl">Clean</button>
    </div>

    <div class="flex-grow overflow-y-auto flex flex-col bg-zinc-800">
      <button
        v-if="hasHistory && showHistory"
        @click.stop="toggleHistory"
        class="text-gray-500 hover:bg-gray-500 dark:hover:bg-gray-900 text-sm w-full mb-1 bg-gray-400 dark:bg-zinc-800 p-2"
      >
        {{ 'Hide Previous Messages' }}
      </button>
      <div class="flex flex-grow flex-col gap-2 h-full overflow-y-auto" ref="messagesContainer">
        <button
          v-if="hasHistory && !showHistory"
          @click.stop="toggleHistory"
          class="text-gray-500 hover:bg-gray-500 dark:hover:bg-gray-900 text-sm w-full mb-1 bg-gray-400 dark:bg-zinc-800 p-2"
        >
          {{ 'Show Previous Messages' }}
        </button>
        <div
          v-for="message in displayedMessages"
          :key="message.id"
          class="message-wrapper"
          :class="{ 'user-message': message.role === 'user', 'mb-1': !message.isHistory || showHistory }"
        >
          <div class="w-full">
            <div :class="message.role === 'user' ? 'dark:bg-zinc-900' : ''">
              <div v-if="Array.isArray(message.content)" class="carousel overflow-x-auto max-w-full flex">
                <div
                  v-for="(content, index) in message.content"
                  :key="index"
                  class="message markdown-body prose prose-sm dark:prose-invert mb-2 whitespace-nowrap"
                  v-html="renderMessage(content)"
                ></div>
              </div>
              <div
                v-else
                class="message markdown-body prose prose-sm dark:prose-invert"
                v-html="renderMessage(message.content || (message.id === lastMessageID ? currentTypedMessage : ''))"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-2 py-2 md:py-4 border-gray-300 dark:border-gray-600 flex">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessage"
        :disabled="isLoading"
        id="newMessageInput"
        class="ps-5 flex-grow px-3 py-3 border dark:bg-zinc-800 border-gray-300 dark:border-gray-600 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
        :placeholder="isLoading ? 'Please wait...' : 'Follow up...'"
      />
      <button v-if="isLoading" @click="stopMessage" class="ml-2 px-1 py-1 rounded text-white">
        <div class="rounded-lg border-2 w-5 h-5 border-white"></div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref as dbRef, get, onChildAdded, onValue, orderByChild, push, query, set } from 'firebase/database';
import { marked } from 'marked';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useDatabase } from 'vuefire';

const props = defineProps({
  startQuestion: String,
});

const emit = defineEmits(['closeChat']);

const showChat = ref(true);
const messages = ref([]);
const newMessage = ref('');
const messagesContainer = ref(null);
const chatReference = ref(null);
const showHistory = ref(false);
const isLoading = ref(false);
const currentTypedMessage = ref('');
const lastMessageID = ref(null);

const uid = useCookie('chat_uid');
const chatID = ref('default');
if (!uid.value) {
  uid.value = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const thirtyMinutesAgo = computed(() => Date.now() - 30 * 60 * 1000);

const hasHistory = computed(() => {
  return messages.value.some((message) => message.timestamp < thirtyMinutesAgo.value);
});

const displayedMessages = computed(() => {
  const sortedMessages = [...messages.value].sort((a, b) => a.timestamp - b.timestamp);
  return sortedMessages.filter((message) => showHistory.value || message.timestamp >= thirtyMinutesAgo.value);
});

const toggleHistory = () => {
  showHistory.value = !showHistory.value;
  setTimeout(() => {
    scrollToBottom();
  }, 100);
};

const closeChat = () => {
  showChat.value = false;
  emit('closeChat');
};

const sendMessage = () => {
  if (newMessage.value.trim() && !isLoading.value) {
    const messageData = {
      content: newMessage.value,
      timestamp: Date.now(),
      id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      role: 'user',
    };
    push(dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/messages`), messageData);
    newMessage.value = '';
    scrollToBottom();
    isLoading.value = true;
  }
};

const stopMessage = () => {
  set(dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/messages/${lastMessageID.value}/stop`), true);
};

const scrollToBottom = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
};

const loadChatHistory = async () => {
  const chatRef = dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/messages`);
  const snapshot = await get(query(chatRef, orderByChild('timestamp')));

  if (snapshot.exists()) {
    const data = snapshot.val();
    const messageArray = Object.entries(data).map(([key, value]) => ({
      id: key,
      ...value,
    }));
    messages.value = messageArray;
    lastMessageID.value = newMessage.id;
    if (newMessage.role === 'assistant') {
      listenToLastMessageContent(newMessage.id);
    }
  }
};

const listenForNewMessages = () => {
  const chatRef = dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/messages`);
  chatReference.value = onChildAdded(query(chatRef, orderByChild('timestamp')), (snapshot) => {
    const newMessage = {
      id: snapshot.key,
      ...snapshot.val(),
    };
    if (!messages.value.some((msg) => msg.id === newMessage.id)) {
      messages.value.push(newMessage);
      lastMessageID.value = newMessage.id;
      if (newMessage.role === 'assistant') {
        listenToLastMessageContent(newMessage.id);
      }
    }
  });
};

const listenToLastMessageContent = (messageId) => {
  const messageRef = dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/messages/${messageId}/content`);
  onValue(messageRef, (snapshot) => {
    if (snapshot.exists()) {
      currentTypedMessage.value = snapshot.val();
      // Update the message content when it's finished
      const messageIndex = messages.value.findIndex((msg) => msg.id === messageId);
      if (messageIndex !== -1) {
        messages.value[messageIndex].content = currentTypedMessage.value;
      }
    }
  });
};

const listenForConversationState = () => {
  const stateRef = dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/state`);
  onValue(stateRef, (snapshot) => {
    const state = snapshot.val();
    isLoading.value = state === 'loading';
    if (!isLoading.value) {
      // Conversation finished, update the last message
      const lastMessage = messages.value[messages.value.length - 1];
      if (lastMessage && lastMessage.id === lastMessageID.value) {
        lastMessage.content = currentTypedMessage.value;
      }
    }
  });
};

const cleanUpChat = () => {
  set(dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/messages`), null);
  set(dbRef(useDatabase(), `chats/${uid.value}/${chatID.value}/state`), null);
  document.querySelector('#newMessageInput').focus();
  messages.value = [];
  isLoading.value = false;
  currentTypedMessage.value = '';
  lastMessageID.value = null;
};

onMounted(async () => {
  await loadChatHistory();
  listenForNewMessages();
  listenForConversationState();

  scrollToBottom();

  //Focus on the input field
  setTimeout(() => {
    document.querySelector('#newMessageInput').focus();
  }, 100);

  if (props.startQuestion && !isLoading.value) {
    newMessage.value = props.startQuestion;
    setTimeout(() => {
      sendMessage();
    }, 1000);
  }
});

onUnmounted(() => {
  if (chatReference.value) {
    chatReference.value();
  }
});

watch(messages, scrollToBottom, { deep: true });

function renderMessage(content) {
  const renderer = new marked.Renderer();

  marked.setOptions({
    renderer: renderer,
    gfm: true,
    breaks: true,
    smartLists: true,
    smartypants: true,
  });

  return marked(content);
}
</script>

<style>
.chat-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.message-wrapper {
  display: flex;
}

.message-wrapper.user-message {
  justify-content: flex-end;
}

.message {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  overflow-wrap: break-word;
}

.user-message .message {
  color: white;
}

.message-wrapper:not(.user-message) .message {
}

/* Markdown Styles */
.markdown-body {
  @apply leading-tight;
}

.markdown-body p {
  @apply leading-normal;
  @apply text-md;
}

.markdown-body img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
}

.markdown-body a {
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

.markdown-body code {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: 0.2em 0.4em;
}

.markdown-body pre {
  @apply bg-white dark:bg-gray-800;
  border-radius: 3px;
  font-size: 85%;
  line-height: 1.45;
  overflow: auto;
  padding: 16px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.user-message .markdown-body a,
.user-message .markdown-body code {
  @apply bg-white dark:bg-gray-800;
  background-color: rgba(255, 255, 255, 0.2);
}

.user-message .markdown-body pre {
  background-color: rgba(255, 255, 255, 0.1);
  @apply bg-white dark:bg-gray-800;
}

.message img {
  width: 200px;
  height: auto;
}

.carousel img {
  width: 200px;
  height: auto;
}

.carousel .message {
  width: 200px;
  overflow-x: hidden;
}
</style>
