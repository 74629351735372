// plugins/numberFormat.js
import { defineNuxtPlugin } from '#app';
import { defaultNumberLocales } from '~/components/utils';
import { numberFormats } from '~/i18n/number-format';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.mixin({
    methods: {
      localizedNumber(number, formatType, passedOptions) {
        const { locale } = useI18n();

        const preferredNumberLocaleCookie = useCookie('preferredNumberLocale');
        const preferredNumberLocale = computed(() => preferredNumberLocaleCookie.value || locale.value);
        const numberLocale = preferredNumberLocale.value;

        //Find the number format for the current locale
        let numberFormat = numberFormats[numberLocale];
        // console.debug(`Number format for locale "${numberLocale}" is ${JSON.stringify(numberFormat)}`);
        if (!numberFormat) {
          // console.debug(
          //   `Number format for locale "${numberLocale}" not found. Tring in the default locales otherwise en-US`,
          // );
          numberFormat = numberFormats[defaultNumberLocales[numberLocale] || defaultNumberLocales['en']];
          // console.debug(`Number format for locale "${numberLocale}" is ${JSON.stringify(numberFormat)}`);
        }

        const options = { style: formatType };

        if (formatType === 'currency') {
          const currency = numberFormat.currency.currency ?? 'USD'; // Provide a fallback currency
          options.currencyDisplay = 'narrowSymbol';
          options.currency = currency;
        }

        // Merge any additional options
        if (passedOptions) {
          Object.assign(options, passedOptions);
        }

        return new Intl.NumberFormat(numberLocale, options).format(number);
      },
    },
  });
});
