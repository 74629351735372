<template>
  <div class="flex flex-col h-screen">
    <AppHeader class="pb-20" />
    <NuxtLayout v-if="error?.statusCode == 404" name="404" class="flex-grow">
      <div class="flex flex-col items-center justify-center h-full p-10">
        <img src="/img/404.gif" alt="404" class="h-[200px] mx-auto mb-10" />
        <h1 class="text-4xl">{{ $t('Ooops! No results found') }}</h1>
        <p class="text-lg">{{ $t('Looks like this page took a wrong turn and ended up in a mysterious land.') }}</p>
        <p class="text-lg mb-10 text-center">
          {{ $t("But don't worry! You can still embark on your next adventure and explore new destinations.") }}
        </p>
        <NuxtLinkLocale to="/">
          <button
            class="mt-4 bg-gradient-to-r from-ts_blue_to to-ts_blue_from text-white font-semibold py-2 px-4 rounded-full"
          >
            {{ $t('Explore from the Homepage') }}
          </button>
        </NuxtLinkLocale>
      </div>
    </NuxtLayout>
    <NuxtLayout v-else-if="error" name="500" class="flex-grow text-center">
      <div class="flex flex-col items-center justify-center h-full p-10">
        <h1 class="text-4xl text-center">{{ $t('Ops, something went wrong while processing your request') }}</h1>
        <p class="text-lg">{{ $t('Looks like this page took has had some errors') }}</p>
        <div v-if="isDev" class="text-lg mt-10 px-10">{{ error }}</div>
        <NuxtLinkLocale to="/">
          <button
            class="mt-10 bg-gradient-to-r from-ts_blue_to to-ts_blue_from text-white font-semibold py-2 px-4 rounded-full"
          >
            {{ $t('Explore from the Homepage') }}
          </button>
        </NuxtLinkLocale>
      </div>
    </NuxtLayout>
    <AppFooter />
  </div>
</template>

<script setup>
const error = useError();
const isDev = process.dev;
</script>

<style scoped>
.button {
  padding: 4px 6px;
  margin: 10px 0px;
  background: black;
  color: white;
}
</style>
