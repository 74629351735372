
// @ts-nocheck
import locale__home_runner_work_web_web_website_assets_translations_en_json from "../assets/translations/en.json";
import locale__home_runner_work_web_web_website_assets_translations_fr_json from "../assets/translations/fr.json";
import locale__home_runner_work_web_web_website_assets_translations_it_json from "../assets/translations/it.json";
import locale__home_runner_work_web_web_website_assets_translations_de_json from "../assets/translations/de.json";
import locale__home_runner_work_web_web_website_assets_translations_es_json from "../assets/translations/es.json";
import locale__home_runner_work_web_web_website_assets_translations_pt_json from "../assets/translations/pt.json";
import locale__home_runner_work_web_web_website_assets_translations_nl_json from "../assets/translations/nl.json";


export const localeCodes =  [
  "en",
  "fr",
  "it",
  "de",
  "es",
  "pt",
  "nl"
]

export const localeLoaders = {
  "en": [{ key: "../assets/translations/en.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_en_json), cache: true }],
  "fr": [{ key: "../assets/translations/fr.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_fr_json), cache: true }],
  "it": [{ key: "../assets/translations/it.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_it_json), cache: true }],
  "de": [{ key: "../assets/translations/de.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_de_json), cache: true }],
  "es": [{ key: "../assets/translations/es.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_es_json), cache: true }],
  "pt": [{ key: "../assets/translations/pt.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_pt_json), cache: true }],
  "nl": [{ key: "../assets/translations/nl.json", load: () => Promise.resolve(locale__home_runner_work_web_web_website_assets_translations_nl_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "~_i18n_i18n_config_ts_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "~/i18n/i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "assets/translations/en.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        "assets/translations/fr.json"
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano",
      "files": [
        "assets/translations/it.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        "assets/translations/de.json"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "name": "Español",
      "files": [
        "assets/translations/es.json"
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "name": "Português",
      "files": [
        "assets/translations/pt.json"
      ]
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "name": "Nederlands",
      "files": [
        "assets/translations/nl.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "about/index": {
      "en": "/about-us",
      "it": "/chi-siamo",
      "es": "/acerca-de",
      "de": "/ueber-uns",
      "pt": "/sobre-nos",
      "fr": "/a-propos-de",
      "nl": "/over-ons"
    },
    "about/[member_name]": {
      "en": "/about-us/[member_name]",
      "it": "/chi-siamo/[member_name]",
      "es": "/acerca-de/[member_name]",
      "de": "/ueber-uns/[member_name]",
      "pt": "/sobre-nos/[member_name]",
      "fr": "/a-propos-de/[member_name]",
      "nl": "/over-ons/[member_name]"
    },
    "partner/list-your-experience": {
      "en": "/partner/list-your-experience",
      "it": "/partner/aggiungi-la-tua-esperienza",
      "es": "/asociado/publica-tu-experiencia",
      "de": "/partner/angebote-inserieren",
      "pt": "/parceiros/adicionar-experiencias",
      "fr": "/partenaire/publier-une-offre",
      "nl": "/nl/partner/promoot-jouw-activiteiten"
    },
    "cookies": {
      "en": "/cookies-policy",
      "it": "/informativa-sui-cookie",
      "es": "/cookie-policy",
      "de": "/cookie-richtlinie",
      "pt": "/polit%C3%ADca-de-cookies",
      "fr": "/politique-en-mati%C3%A8re-de-cookies",
      "nl": "/cookiebeleid"
    },
    "company": {
      "en": "/company-details",
      "it": "/dati-societari",
      "es": "/informaci%C3%B3n-de-la-empresa",
      "de": "/impressum",
      "pt": "/dados-da-empresa",
      "fr": "/coordonn%C3%A9es-de-la-soci%C3%A9t%C3%A9",
      "nl": "/bedrijfsgegevens"
    },
    "contacts": {
      "en": "/contact-us",
      "it": "/contattaci",
      "es": "/contacto",
      "de": "/kontaktiere-uns",
      "pt": "/contacte-nos",
      "fr": "/contactez-nous",
      "nl": "/contacteer-ons"
    },
    "privacy": {
      "en": "/privacy-policy",
      "it": "/informativa-sulla-privacy",
      "es": "/pol%C3%ADtica-de-privacidad",
      "de": "/datenschutz-bestimmungen",
      "pt": "/polit%C3%ADca-de-privicidade",
      "fr": "/politique-sur-la-vie-priv%C3%A9e",
      "nl": "/privacybeleid"
    },
    "destinations": {
      "en": "/destinations",
      "it": "/destinazioni",
      "es": "/destinos",
      "de": "/reiseziele",
      "pt": "/destinos",
      "fr": "/destinations",
      "nl": "/bestemmingen"
    },
    "terms-of-service": {
      "en": "/terms-of-service",
      "it": "/termini-di-servizio",
      "es": "/condiciones-del-servicio",
      "de": "/nutzungsbedingungen",
      "pt": "/termos-de-servi%C3%A7o",
      "fr": "/conditions-d%27utilisation",
      "nl": "/voorwaarden"
    },
    "s/[city_n]/map": {
      "en": "/s/[city_n]/map",
      "it": "/s/[city_n]/mappa",
      "es": "/s/[city_n]/mapa",
      "de": "/s/[city_n]/karte",
      "pt": "/s/[city_n]/mapa",
      "fr": "/s/[city_n]/carte",
      "nl": "/s/[city_n]/kaart"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "assets/translations/en.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "assets/translations/fr.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it-IT",
    "name": "Italiano",
    "files": [
      {
        "path": "assets/translations/it.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "assets/translations/de.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "assets/translations/es.json"
      }
    ]
  },
  {
    "code": "pt",
    "iso": "pt-PT",
    "name": "Português",
    "files": [
      {
        "path": "assets/translations/pt.json"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "nl-NL",
    "name": "Nederlands",
    "files": [
      {
        "path": "assets/translations/nl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
